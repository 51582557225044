.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.activess {
  background-color: black !important;
  color: white !important;
}
@keyframes slidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.animate-slidein {
  animation: slidein 10s ease-out infinite;
}

.animate-scrolling {
  animation: scrolling 10s linear infinite;
  transform-origin: 0% 50%;
}

@keyframes scrolling {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slick-arrow {
  display: none !important;
}
* {
  margin: 0;
  padding: 0;
}
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #fcfafa;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}



.image-four {
  background-image: url("../src/assets/img/bottom.jpg");
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.rate {
  float: left;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: black;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
header {
  background: rgba(232, 226, 226, 0.8);
}
.slider {
  width: 100%;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider img {
  width: 100%;
  height: auto;
}

/* Animation */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  70% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-300%);
  }
  95% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(0);
  }
}
/* YourComponent.css */

.marquee {
  animation: fade 3s infinite; /* Adjust the duration as needed */
}
@keyframes fade {
  0%, 100% {
    opacity: 0;
  }

  10%, 90% {
    opacity: 1;
  }
}
.text-3d {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); /* Adjust the shadow values as needed */
}
/* YourComponent.css */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation {
  display: inline-block;
}

.animation span {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-in-out forwards;
}

.animation span:nth-child(1) {
  animation-delay: 0.2s;
}

.animation span:nth-child(2) {
  animation-delay: 0.4s;
}

.animation span:nth-child(3) {
  animation-delay: 0.6s;
}

/* YourComponent.css */

.buy-container:hover + div {
  display: block;
}

.buy-container + div {
  display: none;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-option {
  /* Adjust the background color as needed */
  color: #ffffff;
  cursor: pointer;
}

.options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}

.options li {
  background-color: #ffffff;
  color: #000000;
  padding: 8px;
  cursor: pointer;
}

.custom-dropdown:hover .options {
  display: block;
}
/* Map container */
.map-container {
  width: 100%; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
}

/* You can add more styles for specific elements within the map container if needed */

